.ngx-slider .ngx-slider-pointer {
    width: 25px!important;;
    height: 25px!important;;
    border: 1px solid $primary-light-color!important;
    background-color: #FFF!important;

    &.ngx-slider-pointer-min {
        border-color: $primary-color!important;
    }

    &:focus {
        outline:none!important;
    }
}

.ngx-slider-floor, .ngx-slider-ceil {
    display: none!important;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
    background-color: #FFF!important;
}

.ngx-slider .ngx-slider-selection {
    background-color: #000000!important;;
}

.ngx-slider[disabled] {
    opacity: .4;
}

.ngx-slider-bubble i {
    margin-left: -4px;
    font-style: normal;
}
