// Global Variables
// ---- Active Colors
$active-color: #007bc3;
$active-light-color: #86a7cc;
$inactive-color: #999;

$primary-color: #16b1f3;
$primary-light-color: #BCBCBC;
$secondary-color: #b9b9b9;
$secondary-light-color:  #f2f2f2;
$tertiary-color: #70d221;


// ---- Background Colors
$default-background-color: #fff;
$lightest-background-color: #efefef;
$lighter-background-color: #f1f2f2;
$light-background-color: #e5e5e5;
$navigation-background-color: #e7e9ea;
$medium-background-color: #c1c1c1;
$dark-background-color: #6c7f8c;

// ---- Fonts
$title-text-color: #2e6da1;
$sub-title-color: #bcbdbf;
$light-text-color: #666;
$lighter-text-color: #808285;
$default-text-color: #000;
$positive-value-color: #000;
$negative-value-color: #db1c5c;
$error-text-color: #db1c5c;
$constructor-font-color: #333;

// ---- Borders
$border-general: 1px solid lightgray;
$border-disabled: 1px solid rgba(0, 0, 0, .15);
$border-light: 1px solid #e6e6e6;
$border-lighter: 1px solid #eee;
$border-lightest: 1px solid #f1f2f2;

// ---- Buttons
$button-applicable-color: #fff;

// ---- Heading Sizes
$h1-size: 21px;
$h2-size: 18px;
$h3-size: 15px;
$h4-size: 12px;
$font-weight-normal: 400;
$font-weight-heavy: bold;

// Box Shadows
$shadow-super-light: 0 3px 9px rgba(132, 127, 127, 0.1);
$shadow-high-dark: 0 6px 12px #333;
$shadow-medium: #666 0 5px 10px;
$shadow-modal: 0 3px 10px #999;

// Break Points
$max-lg-width: 1900px;
$min-lg-width: 1799px;
$min-md-width: 1280px;
$max-md-width: 1279px;
$min-sm-width: 960px;
$max-sm-width: 959px;
$min-xs-width: 600px;
$max-xs-width: 599px;

// Main Body
$body-background-color: $default-background-color;

// --- Buttons
$button-font-size: 12px;
$button-disabled-opacity: 0.65;
$button-disabled-color: #888;
$button-disabled-background-color: #efefef;
$button-disabled-border: $border-disabled;

// ---- Navigation
$nav-bar-collapsed-width: 60px;
$nav-bar-expanded-width: 280px;
$nav-bar-transition: 300ms ease-in-out;
$nav-bar-background: $navigation-background-color;
$nav-bar-border-right: $border-general;
$nav-bar-divider-border: $border-general;
$nav-bar-user-text-color: $light-text-color;
$nav-bar-user-text-size: $h3-size;
$nav-bar-user-sub-text-size: $h4-size;
$nav-bar-submenu-caret-color: #797979;
$nav-bar-submenu-caret-size: 22px;
$nav-bar-submenu-shadow: 1px 1px 3px #666;

$nav-link-header-background: #2D3338;
$nav-link-header-text-color: #BFCDDF;
$nav-link-color-default: #3d3f3c;
$nav-link-color-active: #106FA8;
$nav-link-color-inactive: #3d3f3c;
$nav-link-color-hover-text: #fff;
$nav-link-label-font-size: 15px;
$nav-link-label-font-weight: bold;
$nav-link-icon-size: 20px;
$nav-link-icon-inactive-opacity: 0.5;
$nav-link-icon-active-opacity: 1;
$nav-link-label-inactive-opacity: 0.7;
$nav-link-label-active-opacity: 1;
$nav-link-initials-font-size: 20px;
$nav-link-activate-transitions: 0.25s ease-in-out;
$nav-link-icon-transition: 0.5s ease;
$nav-link-label-transition: 200ms ease-out;

$nav-submenu-border: 1px solid #ccc;

// ---- Tabs
$tab-label-color: #bcbec0;
$tab-label-background-color: #e6e7e8;
$tab-label-active-color: $active-color;
$tab-label-active-background-color: #fff;
$tab-group-background: $default-background-color;

// Toast / Snackbar
$snackbar-button-color: $active-light-color;
$snackbar-button-background-color: none;

// Page Constructor
$constructor-loader-background-color: $default-background-color;
$constructor-view-block-background-color: $default-background-color;
$constructor-border: $border-light;
$constructor-border-view-divided-block: $border-lightest;
$constructor-header-shadow: $shadow-super-light;

// Filters
// ---- Groups
$filter-group-title-color: $title-text-color;
$filter-group-background-color: $default-background-color;

// ---- Selectors
$filter-mat-select-color: $title-text-color;
$filter-mat-select-label-color: $inactive-color;

// ---- Chips
$filter-chip-text-color: $light-text-color;
$filter-chip-background-color: $lightest-background-color;

// ---- Apply/Reset Buttons
$filter-button-applicable-color: #fff;
$filter-button-applicable-background-color: #4ec6bf; // mat-palette($mat-ras-green, 500);

// Search
$search-label-color: $inactive-color;
$search-field-ripple: #fff;
$search-field-color: $title-text-color;
$search-focus-shadow: $shadow-medium;
$search-result-highlight: #f6f6f6;

// ---- Search Items
$search-result-item-color: $title-text-color;
$search-result-category-color: $light-text-color;
$search-result-disabled-color: $light-text-color;

// Metric Selector
$metric-selector-border: $border-lighter;
$metric-selector-options-shadow: $shadow-high-dark;

// Tables
$table-background-color: $default-background-color;
$table-container-background-color: $default-background-color;

// ---- Scroll Bars
$table-scroll-color: #2d8db8;
$table-scroll-background-color: #ffffff;
$table-scroll-track-piece-color: #ffffff;

// ---- Sorting
$table-sort-header-button-color: #808285;

// ---- Row Highlights
$table-row-highlight-row-background-color: navy;
$table-row-highlight-cell-color: #fff;
$table-row-highlight-column-background-color: #ebfbff;
$table-row-highlight-row-even-background-color: #d3f1f9;
$table-row-highlight-row-hover-color: #fff;
$table-row-highlight-row-neutral-color: #fff;
$table-row-highlight-row-link-color: #ccc;
$table-row-highlight-row-hover-background-color: #88adb9;
$table-row-highlight-trend-positive: lightgreen;
$table-row-highlight-trend-negative: hotpink;
$table-row-positive-color: #4ec6bf;
$table-row-negative-color: #d41c5c;
$table-row-neutral-color: #000;

// ---- Column Highlights
$table-column-highlight-background-color: #ebfbff;
$table-column-highlight-background-color-alternate: #d3f1f9;
$table-column-highlight-hover-color: #fff;
$table-column-highlight-hover-background-color: #88adb9;

// ---- Cells
$table-cell-color: $inactive-color;
$table-cell-border-color: #cecece;
$table-cell-border-right: $table-cell-border-color 1px dotted !important;
$table-header-cell-border-right: $table-cell-border-color 1px dotted !important;
$table-cell-border-sticky: $table-cell-border-color 1px solid !important;
$table-super-category-border: $table-cell-border-color 1px solid !important;
$table-header-cell-font-size: 12px;
$table-header-cell-font-weight: normal;
$table-link-color: #2e6da4;

// ---- Actionables
$table-actions-background-color: #d0d0d0;
$table-actions-border-bottom: 2px solid #aaa;
$table-actions-button-header-color: $active-color;

// ---- Paginator
$table-paginator-background-color: $light-background-color;
$table-paginator-border: 2px solid #e3e9ec !important;
$table-paginator-input-background: $default-background-color;
$table-paginator-font-color: $dark-background-color;
$table-paginator-button-color: $dark-background-color;

// Charts
// ---- Tooltips
$chart-tooltip-border: 1px solid lightblue;
$chart-tooltip-color: #333;
$chart-tooltip-background-color: #fff;
$chart-tooltip-title-border: darkred;
$chart-tooltip-sub-title-color: gainsboro;
$chart-tooltip-stacked-row-border: gainsboro;

// Views Component
$views-background-color: $lighter-background-color;
$views-button-background-color: transparent;
$views-button-color: $dark-background-color;
$views-button-active-color: $active-color;

// Beacon
$beacon-scale: 7;
$beacon-repeat: forwards;
$beacon-active-time: 2s;
$beacon-initial-size: 6px;

// Borders
$border-radius: 10px;
$border-radius-small: 5px;
$border-radius-lg: 25px;
