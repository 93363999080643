@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1.2 );
  }
  40%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( 1 );
  }
}

.animated {
    animation-duration: .2s;
    animation-fill-mode: both;
}

@keyframes slideInRight {
    from {
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .slideInRight {
    animation-name: slideInRight;
  }
