//////////////////////////////////////////////////////////////////
// > Clients can have their own color configuration so when adding
// a component that has a primary, secondary, etc color, make
// sure you apply it through the following css class
//////////////////////////////////////////////////////////////////
// > The why? we can not override colors from an injected CSS
// that defines a CSS variable since (IE 11 :C)
// > Overriding a color will be just simple as settings this class
// in the client, and inject it on the configuration.custom.css
//////////////////////////////////////////////////////////////////

.is-color-primary {
    color: $primary-color
}
.is-color-primary-inactive {

}
.is-color-primary-light {
    color: $primary-light-color
}
.is-color-secondary {
    color: $secondary-color;
}
.is-color-opacity-secondary {
    opacity: .6;
}
.is-color-secondary-light {
    color: $secondary-light-color;
}
.is-color-secondary-inactive {}
.is-color-tertiary {
    color: $tertiary-color;
}
.is-background-primary {
    background-color: $primary-color;
}
.is-background-primary-inactive {}
.is-background-primary-light {}
.is-background-secondary {
    background-color: $secondary-color;
}
.is-background-secondary-light {
    background-color: $secondary-light-color;
}
.is-background-secondary-inactive {}


.is-border-color-primary {
    border-color: $secondary-color;
}
.is-border-color-secondary {}

.is-color-primary-on-active.active {
    color: $primary-color;
}

.is-border-color-primary-on-active.active {
    color: $primary-color;
}

.is-color-white {
    color: #fff !important;
}