.ras-btn-link {
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:focus {
        border: none;
        outline: none;
    }
}
