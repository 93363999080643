// CSS property to include in the spacing
$spacing-property: ("margin": "m", "padding": "p");
// CSS property position to include in the spacing
$spacing-position: ("top": "t", "right": "r", "bottom": "b", "left": "l");
// Values to be added as a spacing helper, in the default example, we are multiplying the base padding value
$spacing-values: ("0": 0, "1": 5, "2" : 10, "3": 15, "4": 20, "5": 25, "n5": -25, "n4": -20, "n3": -15, "n2": -10, "n1": -5);

// Iteration to dynamically create the spacing helper functions

@each $spacing-values-key, $spacing-values-value in $spacing-values {

    @each $spacing-property-key, $spacing-property-value in $spacing-property {

        @each $spacing-position-key, $spacing-position-value in $spacing-position {

            // Example output mt-1, mt-2, mt-0
            .#{ $spacing-property-value }#{ $spacing-position-value }-#{ $spacing-values-key } {
                #{ $spacing-property-key }-#{ $spacing-position-key } : #{ $spacing-values-value }px !important;
            }

        }

    }

}
.no-overflow {
    overflow: hidden;
}

