@use '@angular/material' as mat;
@import 'swatches';
@import 'global.variables';

// Material palettes
$primary-color-palette: mat.m2-define-palette($mat-ras-blue, 500);
$accent-palette: mat.m2-define-palette($mat-ras-orange, 500, 100, 800);
$warn-palette: mat.m2-define-palette($mat-ras-red, 500);

// Angular Material Theming
$theme: mat.m2-define-light-theme((
    color: (
        primary: $primary-color-palette,
        accent: $accent-palette,
        warn: $warn-palette,
    ),
    typography: mat.m2-define-typography-config(),
));
@include mat.all-component-themes($theme);

// Rouse Theming
@import 'global.animations';
@import 'components/range-slider';
@import 'components/buttons';
@import 'components/searchbox';
@import 'helpers/borders';
@import 'helpers/spacing';
@import 'helpers/font';
@import 'helpers/colors';
@import 'helpers/alignments';

body.is-loading {
    overflow: hidden;
}

// Material overrides
.mat-expansion-panel-body {
    padding: 10px 0 0 0;
}

.mat-expansion-panel-header, .mat-expansion-panel-content {
    font-family: inherit;
}

.mat-expansion-panel-header.mat-expanded {
    height: 45px;
}

.mat-expansion-panel-header-title .collapsable-header { margin-bottom: 0; }

.overflow-hidden {
    overflow: hidden;
}

.mat-expansion-panel-header-description {
    flex-grow: 0!important;
    margin-right: 0!important;
    opacity: .5;
    font-size: .75rem;
}

.mat-mdc-select-disabled {
    opacity: .5;
}

.mat-mdc-select-placeholder {
    color: rgba(0, 0, 0, 0.7)
}

.mat-mdc-option {
    font-size: inherit;
}
