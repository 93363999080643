@import 'theme.master';
@import 'breakpoints';
// @import 'node_modules/flag-icons/css/flag-icons.min.css';

// ---------------- General Styling
:root {
    // MAT CSS GLOBAL VARIABLES
    --mdc-filled-text-field-error-label-text-color: #d41c5c;
    --mat-form-field-error-text-color: #d41c5c;
    --mat-form-field-subscript-text-font: Roboto, sans-serif;
    --mat-form-field-subscript-text-line-height: 1rem;
    --mat-form-field-subscript-text-size: .75rem;
    --mat-form-field-subscript-text-tracking: .025rem;
    --mat-form-field-subscript-text-weight: 400;
}

body {
    margin: 0;
    padding: 0;
    background-color: $body-background-color;
}


// General fixes
// ---- Super hacky cheap sort fix until Material gets their crap together.
.mat-mdc-header-cell
.mat-sort-header-container.mat-sort-header-sorted
.mat-sort-header-arrow {
    opacity: 1 !important;
}


.mat-mdc-select-panel {
    --mat-select-panel-background-color: #EFEFEF;
    position: absolute !important;
    left: 0 !important;
    min-width: 100% !important;
    width: auto !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
    white-space: nowrap !important;
}

mat-expansion-panel {
    --mat-expansion-container-text-size: 14px;
    --mat-expansion-header-expanded-state-height: 64px;
    --mat-expansion-header-collapsed-state-height: 48px;
}

mat-divider {
    --mat-divider-width: 1px;
    --mat-divider-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-panel {
    /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
    &.mat-menu-after {
        margin-left: -16px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
    &.mat-menu-before {
        margin-right: -16px;
    }
}

.mat-mdc-checkbox .mdc-checkbox {
    --mdc-checkbox-selected-pressed-state-layer-color: transparent;
    --mdc-checkbox-selected-focus-state-layer-color: transparent;
    --mdc-checkbox-selected-focus-icon-color: transparent;
    --mdc-checkbox-selected-hover-icon-color: transparent;
    --mdc-checkbox-selected-pressed-state-layer-opacity: 0;


   + label {
    white-space: nowrap;
   }
}


.cdk-overlay-pane {
    font-size: 16px;

    &:not(:has(> .currency-selector-opts-panel)):not(.mat-mdc-select-panel-above):not(:has(> .mat-mdc-autocomplete-panel)) {
        padding: 0 16px 0 16px !important;
        box-sizing: content-box;
        &:has(> .mat-menu-before) {
            transform: translateX(16px) translateY(-48px);
        }
        &:has(> .mat-menu-after) {
            transform: translateX(-16px) translateY(-48px);
        }
    }
}

mat-snack-bar-container {
    color: #ffffff;
    background-color: #323232;
}

.chips-save-content {
    .mdc-text-field,
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
        padding: 0;
    }
}

.mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
    margin: 0 4px;
}

.ras-select-panel {
    position: relative;
    top: 0;
    left: 0;
    border-radius: 4px !important;
}

.mat-mdc-select.chevron-arrow {
    .mat-mdc-select-trigger {
        line-height: inherit;
        height: 100%;
        .mat-mdc-select-arrow-wrapper {
            padding: 0 7px;
            height: unset;
            flex-shrink: unset;
            display: inline-block;
            border: 1px solid;
            .mat-mdc-select-arrow {
                border: solid;
                border-width: 0 0.2em 0.2em 0;
                display: inline-block;
                padding: 0.2em;
                transform: rotate(45deg);
                width: 0;
                height: 0;
                margin: 0 4px;
                svg {
                    display: none;
                }
            }
        }

        .mat-mdc-select-value {
            .mat-mdc-select-placeholder {
                font-family: "Montserrat", sans-serif !important;
                font-size: 15px;
                line-height: 18px;
                margin-left: 10px;
            }
        }
    }
}

.advanced-search-location-menu {

    .mat-mdc-menu-content {
        padding: 0 !important;
    }
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    --mat-text-button-state-layer-color: unset !important;
    --mat-snack-bar-button-color: #fb631a;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    --mat-text-button-state-layer-color: unset !important;
    --mat-snack-bar-button-color: #fb631a;
}

ras-currency-selector {
    height: 100%;
    max-height: 30px;

    .mat-mdc-select-trigger {
        height: 100%;
        max-height: 30px;
    }
    .mat-mdc-select-arrow-wrapper {
        height: unset;
    }
}

ras-filter-min-max {
    .mdc-text-field {
        overflow: visible;
    }
}

.mat-mdc-autocomplete-panel {
    --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.4);
    --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.4);
}

.chips-save-container {
    --mdc-filled-text-field-container-color: transparent;
    --mat-form-field-hover-state-layer-opacity: 0;
    --mat-form-field-focus-state-layer-opacity: 0;
}