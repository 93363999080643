.search-autocomplete {
    margin-top: -6px;
}

.ras-searchbox-item {
    font-size: .9rem;

    .option-count {
        margin-left: auto;
    }

    .option-value {
        width: 100%;
        display: flex;
        justify-content: stretch;
        padding-right: 5px;
        box-sizing: border-box;
        overflow: hidden;
        text-transform: capitalize;

        fa-icon {
            margin-right: 10px;
            font-size: 0.8rem;
            display: inline-block;
        }
    }

    .mdc-list-item__primary-text {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .result-field-type {
        font-size: .7rem;
        padding-left: 5px;
    }

    .result-field-value {
        overflow: hidden;
        text-overflow: ellipsis;

        strong {
            font-weight: 700;
        }
    }
}


div.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-visible {
    padding: 0;
}

.search-autocomplete {
    .mat-mdc-option.mat-mdc-option-active {
        background: rgba(0, 0, 0, .6);
        color: #fff;
    }

    mat-option.mat-mdc-option.mdc-list-item:not(.mdc-list-item--disabled) {
        background-color: #4b4b4b;
        color: #FFF;
    }

    mat-option.mat-mdc-option:hover.mdc-list-item:not(.mdc-list-item--disabled) {
        background-color: rgba(0, 0, 0, .5);
        color: #FFF;
    }

    .mat-mdc-option:hover:not(.mat-option-disabled),
    .mat-mdc-option:focus:not(.mat-option-disabled) {
        color: inherit;
    }
}
