.is-rounded {
    border-radius: $border-radius!important;
}

.is-rounded-top {
    border-radius: $border-radius $border-radius 0 0;
}

.is-rounded-top-lg {
    border-radius: $border-radius-lg $border-radius-lg 0 0;
}

.is-rounded-small {
    border-radius: $border-radius-small;
}

.is-bordered {
    border-width: 1px;
    border-style: solid;
    border-color: $primary-light-color;
}

@media screen and ( min-width: $min-xs-width ) {
    .is-bordered-desktop {
        border-width: 1px;
        border-style: solid;
        border-color: $primary-light-color;
    }
}



.has-bordered-bottom {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $primary-light-color;
}
