//////////////////////////////////////////////////////////////////
// > Clients can have their own font configuration so when adding
// a component that has a different font , make
// sure you apply it through the following css class
//////////////////////////////////////////////////////////////////
// > The why? we can not override fonts from an injected CSS
// that defines a CSS variable since (IE 11 :C)
// > Overriding a font will be just simple as settings this class
// in the client, and inject it on the configuration.custom.css
//////////////////////////////////////////////////////////////////

.is-main-font {
    font-size: 15px;
    line-height: 23px;

    @media screen and ( min-width: $min-xs-width ){
        font-size: 16px;
        line-height: 26px;
    }
}

.is-font-h1 {
    font-size: 1.6rem;
}

.is-font-h2 {
    font-size: 1.4rem;
}

.is-font-h3 {
    font-size: 1.2rem;
}
.is-font-h4 {
    font-size: 1.1rem;
}
.is-font-h5 {
    font-size: .942rem;
}
.is-font-h6 {}

.is-font-xs {
    font-size: .8rem
}

.is-font-sm {
    font-size: .9rem
}

.is-font-regular {}

.is-font-bold {
    font-weight: bold;
}

.is-font-bolder {
    font-weight: bolder;
}

.is-font-500 {
    font-weight: 500;
}

.is-font-600 {
    font-weight: 600;
}

.is-font-700 {
    font-weight: 700;
}


.is-text-capitalized {
    text-transform: capitalize;
}


